// 
// This file changes Bootstrap core variables plus adds new ones.
// 
// We do not recommend that you edit here directly.
// Instead, add your overrides in the _user-variables.scss file.
// This way, getting future updates will be much simpler.
//


// Config

$enable-negative-margins: true !default;


// Sizes

$sizes: (
  10: 10%,
  20: 20%,
  25: 25%,
  30: 30%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  100: 100%
) !default;


// Color system

$min-contrast-ratio: 2 !default;

$gray-600:  #6b819c !default;
$purple:    #6672e8 !default;
$primary:   #2083fe !default;
$secondary: #5A6C90 !default;
$success:   #34bd91 !default;
$info:      #18b7ff !default;
$warning:   #fdc724 !default;
$danger:    #bd4242 !default;
$light:     #f6f9fc !default;
$dark:      #1e2e50 !default;
$sky:       #e9f4ff !default; // new
$pistachio: #ddf5f4 !default; // new
$darkblue:  #1f2c73 !default; // new


// Typography

$font-family-base:    'SpartanMB', sans-serif !default;
$display-font-sizes: (
  1: 5.75rem,
  2: 5rem,
  3: 4rem,
  4: 3.2rem,
  5: 2.85rem,
  6: 2.5rem
) !default;
$font-size-sm:        .85rem !default;
$font-size-xs:        .7rem !default; // new
$text-muted:          #8fa2bf !default;
$font-weight-bold:    500 !default;
$font-weight-bolder:  600 !default;
$hr-color:            #dee2e6 !default;
$hr-opacity:          1 !default;


// Body

$body-bg:       $light !default;
$body-color:    $dark !default;
$border-color:  #e9ecef !default;


// Links

$link-decoration:       none !default;
$link-hover-decoration: underline !default;


// Shadows

$box-shadow-sm:       0 .125rem .25rem rgba(35,38,45, 0.09) !default;
$box-shadow:          0 .5rem 1.25rem rgba(35,38,45, 0.125) !default;
$box-shadow-lg:       0 1rem 3rem rgba(35,38,45, .275) !default;
$box-shadow-light-sm: 0 .125rem .25rem rgba(35,38,45, 0.05) !default; // new
$box-shadow-light:    0 .5rem 1.25rem rgba(35,38,45, 0.05) !default; // new
$box-shadow-light-lg: 0 1rem 3rem rgba(35,38,45, .05) !default; // new


// Navbar

$navbar-padding-y:                  .8rem !default;
$navbar-toggler-padding-x:          0 !default;
$navbar-nav-link-padding-x:         1.2rem !default;
$navbar-dropdown-box-shadow-lg:     0 1.5rem 3rem rgba(75,78,88, 0.175) !default; // new

$navbar-light-color:                #1e2e50 !default;
$navbar-light-hover-color:          $darkblue !default;
$navbar-light-active-color:         $darkblue !default;
$navbar-light-toggler-border-color: transparent !default;
$navbar-light-brand-color:          $dark !default;
$navbar-light-brand-hover-color:    $darkblue !default;

$navbar-dark-color:                 #fff !default;
$navbar-dark-hover-color:           #e2e5e8 !default;
$navbar-dark-toggler-border-color:  transparent !default;


// Navs

$nav-tabs-link-active-bg:           transparent !default;
$nav-tabs-link-active-color:        $darkblue !default;
$nav-tabs-link-active-border-color: transparent transparent $primary !default;
$nav-tabs-link-hover-border-color:  transparent !default;
$nav-tabs-link-color:               #8094a7 !default; // new
$nav-tabs-link-hover-color:         #5e778c !default; // new
$nav-tabs-link-padding-y:           1.25rem !default; // new


// Alerts

$alert-bg-scale:    7% !default;
$alert-color-scale: 0% !default;


// Dropdowns

$dropdown-font-size:          .95rem !default;
$dropdown-border-color:       rgba($dark, .09) !default;
$dropdown-link-color:         #435a71 !default;
$dropdown-link-hover-color:   $dark !default;
$dropdown-link-active-color:  $dark !default;
$dropdown-link-hover-bg:      none !default;
$dropdown-link-active-bg:     none !default;
$dropdown-padding-y:          .85rem !default;
$dropdown-header-padding:     0 1rem .5rem 1.25rem !default;
$dropdown-header-color:       #222 !default;
$dropdown-item-padding-y:     .35rem !default;
$dropdown-item-padding-x:     1.25rem !default;
$caret-spacing:               .5rem !default;


// Buttons

$btn-padding-y:     .4rem !default;
$btn-padding-x:     1.1rem !default;
$btn-padding-y-sm:  .3rem !default;
$btn-padding-x-sm:  .6rem !default;
$btn-padding-y-lg:  .8rem !default;
$btn-padding-x-lg:  1.85rem !default;
$btn-font-size-lg:  1.1rem !default;


// Badges
$badge-font-size:       .75rem !default;
$badge-padding-y:       .25rem !default;
$badge-padding-x:       .65rem !default;
$badge-border-radius:   .35rem !default;
$badge-padding-y-lg:    .7rem !default; // new
$badge-padding-x-lg:    1.1rem !default; // new
$badge-font-size-lg:    .9rem !default; // new


// Cards

$card-spacer-y:     1.5rem !default;
$card-spacer-x:     1.5rem !default;
$card-border-color: rgba($dark, .09) !default;
$card-cap-bg:       transparent !default;


// List groups

$list-group-border-color:   rgba($dark, .09) !default;
$list-group-item-padding-y: 1rem !default;
$list-group-bg:             transparent !default;


// Forms

$input-placeholder-color:   rgba($darkblue, .4) !default;
$input-border-color:        #dee2e6 !default;
$input-focus-box-shadow:    none !default;
$input-focus-border-color:  $primary !default;

$input-group-addon-bg:      #fff !default;
$input-group-addon-color:   $darkblue !default;

$form-check-input-width:            1rem !default;
$form-check-padding-start:          $form-check-input-width + .5rem !default;
$form-check-input-bg:               #dee2e6 !default;
$form-check-input-border:           none !default;
$form-check-input-focus-box-shadow: none !default;
$form-check-input-active-filter:    brightness(95%) !default;

$form-switch-color:         #fff !default;
$form-switch-width:         3em !default;
$form-switch-focus-color:   #fff !default;


// Pagination

$pagination-color:        $dark !default;
$pagination-bg:           transparent !default;
$pagination-border-width: 0 !default;
$pagination-hover-color:  $dark !default;
$pagination-hover-bg:     #f8f9fa !default;
$pagination-active-bg:    $dark !default;
$pagination-active-color: $light !default;
$pagination-padding-x:    .85rem !default;


// Popover

$popover-header-bg:       #fff !default;
$popover-border-color:    #dee2e6 !default;
$popover-body-padding-y:  .75rem !default;


// Breadcrumbs

$breadcrumb-bg:           none !default;
$breadcrumb-padding-x:    0 !default;
$breadcrumb-active-color: $darkblue !default;


// Modals

$modal-backdrop-opacity:  0.85 !default;
$modal-backdrop-bg:       $dark !default;
$modal-md:                550px !default;


// Tables

$table-cell-padding-y:    1rem !default;
$table-cell-padding-x:    1rem !default;
$table-cell-padding-y-sm: 1rem !default;
$table-cell-padding-x-sm: 1rem !default;
$table-striped-bg:        $light !default;
$table-hover-bg:          $light !default;
$table-font-size-sm:      .9rem !default; // new
$table-th-color:          $dark !default; // new
$table-th-font-size:      .7rem !default; // new


// Transitions

$bg-transition: background-color .2s linear !default; // new


// Navs

$nav-pills-link-active-color: $dark !default;
$nav-pills-link-active-bg:    #fff !default;


// Statcards

$statcard-padding-y:          .9rem !default; // new
$statcard-padding-x:          1.5rem !default; // new
$statcard-change-size:        .8rem !default; // new
$statcard-label-size:         .75rem !default; // new
$statcard-label-font-weight:  600 !default; // new


// Close

$btn-close-width: .7em !default;


// Z-index

$zindex-navbar: 1034 !default; // new
